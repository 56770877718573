import Image from 'next/image';
import Link from 'next/link';
import { ChevronLeftIcon } from '@heroicons/react/solid';

export default function Custom404() {
  return (
    <section className="bg-white py-[60px] border-t min-h-[500px]">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 xl:grid-cols-12 xl:gap-7 xs:gap-0">
          <div className="relative xl:col-span-8 xs:col-span-12 xs:w-[90%] xs:mx-auto  sm:col-span-12  text-center">
            <Image src={'https://d2o2utebsixu4k.cloudfront.net/404.png'} className="block mx-auto" width="554" height="363" alt="404 page" />
          </div>
          <div className="xl:col-span-4 xs:col-span-12 sm:col-span-12 text-center">
            <h1 className="md:text-[200px] font-bold xs:mb-2 mb-[15px] text-[#65d40a] xs:leading-[160px] xs:text-[160px] md:leading-[200px]">
              404
            </h1>

            <span className="text-2xl leading-9 inline-block  ">Oops! That page can't be found</span>
            <div className="mt-[30px] text-center items-center justify-center flex">
              <Link href="/">
                <a className=" sm:min-w-[210px] min-w-[219px] inline-flex  items-center justify-between h-[38px] text-sm  text-white font-bold bg-black rounded ">
                  <div className="w-[42px] h-full bg-[#1d1d1d] inline-flex items-center pl-[1px] rounded">
                    <ChevronLeftIcon className="w-[28px] h-[28px] mt-[1px] -mr-[2px] text-[#26d400]" />
                  </div>
                  <div className="pr-3 pl-[15px]">Go back to home page</div>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
